import { jsx as _jsx } from "react/jsx-runtime";
// components
import Image from '../../image';
export default function AvatarPreview(_a) {
    var file = _a.file;
    if (!file) {
        return null;
    }
    var imgUrl = typeof file === 'string' ? file : file.preview;
    return (_jsx(Image, { alt: "avatar", src: imgUrl, loading: "lazy", sx: {
            zIndex: 8,
            overflow: 'hidden',
            borderRadius: '50%',
            position: 'absolute',
            width: "calc(100% - 16px)",
            height: "calc(100% - 16px)",
        } }));
}
